.aboutContainer {
  display: flex;
  flex-direction: column;
}

.aboutContent {
  padding: 50px;
}

.aboutContent p {
  color: white;
  font-family: "times new roman";
  letter-spacing: .2em;
  font-size: 1.5em;
  line-height: 2;
}
