.container {
  overflow: auto;
}

.dashContainer {
  box-sizing: border-box;
  text-transform: uppercase;
  color: rgba(25, 25, 25, 1);
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 0em;
  font-size: 9px;
  font-family: "Space Mono";
  margin: 14px;
  min-height: calc(100vh - 115px);
  height: auto;
}

.dashForm {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.dashUploadInput {
  width: 300px;
  height: 24px;
  margin-bottom: 14px;
}

.dashLabel {
  width: 300px;
  border: 0;
  outline: 0;
  text-align: left;
  margin-bottom: 4px;
}

.dashInputField {
  width: 300px;
  height: 24px;
  margin-bottom: 14px;
  background-color: #efefef;
  border: 1px solid rgba(25, 25, 25, 1);
  padding: 2px;
}

.dashTextArea {
  width: 300px;
  height: 250px;
  margin-bottom: 14px;
  background-color: #efefef;
  border: 1px solid rgba(25, 25, 25, 1);
  padding: 2px;
}

.dashTextArea:focus {
  outline: none;
}

.dashSelect {
  width: 300px;
  height: 24px;
  margin-bottom: 14px;
  color: rgba(25, 25, 25, 1);
  background-color: #efefef;
  border: 1px solid rgba(25, 25, 25, 1);
  border-radius: 0px !important;
  padding: 2px;
}

.dashSelect:focus,
.dashInputField:focus,
.dashUploadInput:focus {
  outline: none;
}

.dashButton,
.deleteButton {
  display: inline-block;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 1px solid rgba(25, 25, 25, 1);
  width: 125px;
  color: rgba(25, 25, 25, 1);
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 0em;
  font-size: 9px;
  text-align: justify;
  font-family: "Space Mono";
  text-align: center;
  background-color: transparent;
  height: 32px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 14px;
}

.dashButton:hover {
  color: white;
  background-color: rgba(25, 25, 25, 1);
}

.deleteButton {
  color: red;
  border: 1px solid red;
}

.deleteButton:hover {
  color: white;
  background-color: red;
}

.progressBar {
  height: 24px !important;
  background-color: #efefef !important;
  width: 300px;
  border: 1px solid rgba(25, 25, 25, 1);
  border-radius: 0px !important;
  overflow: hidden;
  margin-bottom: 14px;
}

.photoVideoSwitch input[type="checkbox"] {
  display: none;
  visibility: hidden;
}

.photoVideoSwitch {
  --size: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  clear: both;
  margin-bottom: 14px;
}

.photoVideoSwitch label {
  display: inline-block;
  width: var(--size);
  height: calc(var(--size) / 2);
  box-sizing: border-box;
  border: 1px solid;
  position: relative;
  cursor: pointer;
  transition: 0.75s ease;
  color: rgba(25, 25, 25, 1);
}

.photoVideoSwitch input[type="checkbox"]:checked + label {
  background: #efefef;
}

.photoVideoSwitch input[type="checkbox"]:checked + label:before {
  left: calc(var(--size) / 2);
}

.photoVideoSwitch label:before {
  transition: all 0.3s ease;
  content: "";
  width: calc((var(--size) / 2) - 5px);
  height: calc((var(--size) / 2) - 5px);
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(25, 25, 25, 1);
}

.switchLabel {
  display: inline-block;
  margin-left: 8px;
  margin-right: 8px;
}

.dashImageContainer {
  height: 104px;
  width: 300px;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scrollbar-color: grey #616467;
  border: 1px solid rgba(25, 25, 25, 1);
  margin-bottom: 14px;
  background-color: #efefef;
}

.dashImageContainer img, .dashImageContainer video {
  width: auto;
  height: 100px;
  margin: 0;
  box-sizing: border-box;
}

