.footer {
  position: relative;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: rgba(235, 234, 230, 1);
  border-top: 1px solid rgba(25, 25, 26, 1);
}

.contactContainer {
  display: flex;
  align-items: center;
}

.ownerButton {
  border: none;
  background-color: transparent;
  padding: 0;
}

.footerContact,
.ownerButton {
  color: rgba(25, 25, 26, 1);
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 0em;
  font-size: 9px;
  text-align: justify;
  font-family: "Space Mono";
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  cursor: pointer;
  margin: 0 10px;
}
