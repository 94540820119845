html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100vh; 
  width: 100%;
  background-color: rgba(235, 234, 230, 1);
}

a {
  text-decoration: none;
}


.ril__toolbar {
  background-color: rgba(0, 0, 0, 0);
}

.ril__navButtonPrev {
  left: 0;
  background: rgba(0, 0, 0, 0)
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==)
    no-repeat center !important;
}

.ril__navButtonNext {
  right: 0;
  background: rgba(0, 0, 0, 0)
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+)
    no-repeat center !important;
}
