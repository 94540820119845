.albumContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 55px);
}

.albumHeader {
  height: 180px;
  border-bottom: 1px solid rgba(25, 25, 26, 1);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.albumInfoWrapper {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  max-width: 65%;
}

.albumTitle {
  color: rgba(25, 25, 25, 1);
  text-transform: uppercase;
  line-height: 1.2;
  letter-spacing: 0em;
  font-size: 9px;
  text-align: left;
  font-family: "Space Mono";
  font-weight: 400;
  font-style: normal;
}

.albumInfo {
  color: rgba(25, 25, 25, 1);
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 0em;
  font-size: 9px;
  text-align: justify;
  font-family: "Space Mono";
  font-weight: 400;
  font-style: normal;
  margin: 0 15px;
}

.mediaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35%;
  min-height: calc(100svh - 180px - 55px);
  margin-top: 16px;
}

.galleryImage img,
.galleryImage video {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
  border: 1px solid rgba(25, 25, 26, 1);
  cursor: pointer;
  margin-bottom: 16px;
}

.ril__toolbar {
  background-color: rgba(0, 0, 0, 0);
}

@media screen and (max-width: 900px) {
  .mediaContainer {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .mediaContainer {
    width: 100%;
    padding: 0 16px;
  }
}
