.headerContainer {
  height: 60px;
  width: 100%;
  top: 0%;
  left: 0%;
  right: 0%;
  background-color: rgba(235, 234, 230, 1);
  z-index: 1000;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(25, 25, 26, 1);
  position: relative;
}

.headerNavContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  margin: 0;
  width: 100%;
}

.headerTitleContainer {
  text-transform: uppercase;
  line-height: 0.9;
  letter-spacing: 0em;
  font-size: 13px;
  text-align: left;
  font-family: "Space Mono";
  font-weight: 700;
  font-style: normal;
}

.headerTitle {
  display: block;
  margin: 0;
  text-decoration: none;
  color: rgba(25, 25, 25, 1);
}

.headerLinksContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.headerLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: rgba(25, 25, 25, 1);
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 0em;
  font-size: 9px;
  text-align: justify;
  font-family: "Space Mono";
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  margin-left: 16px;
  border: none;
  background-color: transparent;
  padding: 0;
}

.headerLink:hover {
  cursor: pointer;
}

.dropdownContainer {
  transform: translateY(-60px);
  position: absolute;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: rgba(235, 234, 230, 1);
  z-index: 950;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(25, 25, 26, 1);
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.dropdownNavContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 16px;
  margin: 0;
  width: 100%;
  min-width: min-content;
}

.dropdownLinks {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  min-width: min-content;
  gap: 16px;
}

.dropdownLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: rgba(25, 25, 25, 1);
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 0em;
  font-size: 9px;
  text-align: justify;
  font-family: "Space Mono";
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  border: none;
  background-color: transparent;
  padding: 0;
  white-space: nowrap;
}

@media screen and (max-width: 991px) {
  .dropdownNavContainer {
    justify-content: flex-start;
  }

  .dropdownLinks {
    justify-content: flex-start;
  }
}
