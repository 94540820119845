.modalBackground {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: rgb(213, 213, 213);
  border: 1px solid rgba(25, 25, 26, 1);
  max-width: 350px;
  padding: 14px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.75);
  position: relative;
}

.closeButton {
  color: rgba(25, 25, 25, 1);
  top: 2px;
  right: 2px;
  position: absolute;
  margin: 0;
  border: 0;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background: none;
}

.closeButton:hover {
  transform: scale(1.25);
}

.formGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
}

.formLabel {
  color: rgba(25, 25, 25, 1);
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 0em;
  font-size: 9px;
  margin-right: auto;
  font-family: "Space Mono";
  margin-bottom: 2px;
}

.formField {
  border: 1px solid rgba(25, 25, 26, 1);
  line-height: 1.3;
  letter-spacing: 0em;
  font-size: 12px;
  height: 32px;
  width: 275px;
  padding: 0 10px;
  margin-bottom: 14px;
}

.formField:focus {
  outline: none;
}

.loginButton {
  display: inline-block;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 1px solid rgba(25, 25, 26, 1);
  width: 125px;
  color: rgba(25, 25, 25, 1);
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 0em;
  font-size: 9px;
  text-align: justify;
  font-family: "Space Mono";
  text-align: center;
  background-color: transparent;
  height: 32px;
  margin-left: auto;
  margin-right: auto;
}

.loginButton:hover {
  color: white;
  background-color: rgba(25, 25, 25, 1);
}
