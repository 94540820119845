.dashboardHeader {
  display: flex;
  align-items: center;
  justify-content: center;
background-color: rgba(235, 234, 230, 1);
  padding: 10px 0;
  z-index: 10;
  height: 55px;
  width: 100%;
  border-bottom: 1px solid rgba(25, 25, 26, 1);
  box-sizing: border-box;
}

.dashboardHeaderNav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
}

.dashboardHeaderNavItem {
  text-align: center;
}

.dashboardHeaderButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: rgba(25, 25, 25, 1);
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 0em;
  font-size: 9px;
  font-family: "Space Mono";
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  background: none;
  border: none;
  text-align: center;
  margin: 0 10px;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.dashboardHeaderButtonActive {
  transform: scale(1.25);
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.dashboardHeaderButtonActive span {
  width: 100%;
  height: 100%;
}
