html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: rgba(235, 234, 230, 1);

  
  // remove border radius for uniform styling
  input,
  select,
  textarea,
  button,
  option {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0px;
  }
}
