.titleContainer {
  height: 180px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px;
  text-transform: uppercase;
  line-height: 1.2;
  letter-spacing: 0em;
  font-size: 9px;
  text-align: left;
  font-family: "Space Mono";
  font-weight: 400;
  font-style: normal;
}



.titleInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 50vw;
  flex: 1;
}

.title {
  display: flex;
  margin-top: 0;
}

.view {
  display: flex;
  margin-bottom: 0;
  text-decoration: none;
  color: black;
}

.view a {
  text-decoration: none;
  color: black;
}

.titleDescriptionWrapper {
  display: flex;
  width: 50vw;
  flex: 1;
}
