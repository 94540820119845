.homeContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100vw;
  margin: auto;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100svh - 55px);
  width: 100%;
}

.homeBanner {
  border-top: 1px solid #000;
}

.homeBanner img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-bottom: 1px solid #000;
}

.imagesContainer {
  display: flex;
  flex-direction: row;
  width: 100vw;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  width: calc(100vw / 4);
  border-right: 1px solid #000;
}

.noBorderRight {
  border-right: none;
}

.imageWrapper {
  height: 100%;
}

.imageWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0 0 0 1px #000;
}

@media (max-width: 768px) {
  .imagesContainer {
    flex-wrap: wrap;
  }

  .imageContainer {
    width: 50vw;
  }
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}
